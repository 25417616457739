// import store from '@/store'
import {analytics} from "@/firebase";
import {imageConversion, compress, canvastoDataURL} from 'image-conversion';
export default {
  // eslint-disable-next-line no-unused-vars

install(Vue, options) {

  // this is to help firebase data object convert to a usable array
  Vue.prototype.$objectToArray = function (originalArr, filterId, filterVal) {

      // process.env.NODE_ENV === "development" ? console.log('preflight $objectToArray', typeof originalArr, originalArr, filterId, filterVal) : null
      const loopArr = Object.keys(originalArr).map(async (arrItem) => {
        return originalArr[arrItem]
      });

      return Promise.all(loopArr).then(results => {
        // process.env.NODE_ENV === "development" ? console.log('results of loop', results) : null
        if(filterId != null || filterId != undefined){
          const filtered_results = results.filter(item => {
            return Object.entries(item).some(k => k[0].includes(filterId) && k[1] == filterVal)
          });
          return filtered_results
        }
        else{
          return results
        }

      });
  };


  // this is to help firebase data object convert to a usable array
  Vue.prototype.$isEmpty = function (obj, keyy) {
    if(keyy != undefined){
      return Object.hasOwnProperty.call(obj, keyy)
    }
    else{
      for(var key in obj) {
        if(Object.prototype.hasOwnProperty.call(obj,key))
        return false;
      }
      return true;
    }
  };

  // this is to help firebase data object convert to a usable array
  Vue.prototype.$isUndefined = function (thing) {
    if (thing == "undefined" || thing == null){
      return true;
    }
    else{
      return false;
    }
  };


  // this is to help firebase data object convert to a usable array
  Vue.prototype.$clone = function (objectArray) {
    return JSON.parse(JSON.stringify(objectArray))
  };






  Vue.prototype.$logEvent = function (action, data){
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {

      process.env.NODE_ENV === "development" ? console.log( 'analytics...',  data) : analytics.logEvent(action, data);
      // Vue.analytics.logEvent(category, action, label)
    });
  };

  // async Vue.prototype.$imageConversion = function (method, options) {
  //     let result = await imageConversion[method](options));
  //     return result
  // };



  // // 1. add global method or property
  // Vue.myGlobalMethod = function () {
  //   // some logic ...
  // }

  // 2. add a global asset
  // Vue.directive('my-directive', {
  //   bind (el, binding, vnode, oldVnode) {
  //     // some logic ...
  //   }
  //   ...
  // })

  // 3. inject some component options
  // Vue.mixin({
  //   created: function () {
  //     // some logic ...
  //   }
  //   ...
  // })


  // Vue.mixin({
  //   data: function () {
  //     return {
  //       // some logic ...
  //
  //     }
  //   }
  // })



  // // 4. add an instance method
  // Vue.prototype.$myMethod = function (methodOptions) {
  //   // some logic ...
  // }
  }
}
