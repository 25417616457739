<template>
  <v-app :class="{'mobile' : $vuetify.breakpoint.smAndDown, 'desktop' : $vuetify.breakpoint.mdAndUp}">
      <router-view/>
      <v-bottom-sheet v-model="showSearch">
        <v-sheet
          class="text-center"
          height="200px"
        >
        <search-mobile v-if="showSearch" @close-modal="closeModal"></search-mobile>
        </v-sheet>
      </v-bottom-sheet>
  </v-app>
</template>

<script>
const searchMobile = () => import("@/components/shared/search-lite-mobile.vue");
export default {
  name: 'App',
  components: {
    searchMobile
  },
  computed:{
    showSearch:function (){ return this.$store.state.launchSearch},
  },
  data: () => ({
    dialog:false,
  }),

  methods:{
    closeModal(val){
      process.env.NODE_ENV === "development" ? console.log( 'close modal', val ) : null;
      if(val === true){this.dialog = false}
    },
  },
  mounted(){

  }
};
</script>

<style lang="scss">
.search-container-mobile{
  min-height: 100vh;
  min-height: -webkit-fill-available;
  z-index: 99999;
  position: absolute;
  background: white;
}
</style>
