import Vue from "vue";
import Router from "vue-router";
import store from "@/store/";

import {auth} from '@/firebase';

function loadView(src, view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/${src}/${view}.vue`)
}

function $isUndefined(thing) {
  if (thing === "undefined" || thing === null){
    return true;
  }
  else{
    return false;
  }
}


Vue.use(Router)

let router = new Router({
  mode: 'history',
  routes: [
  {
    path: '*',
    redirect: '/',
    component: loadView('views','Home')
  },
  {
    path: '/emailAuth',
    name: 'emailAuth',
    component: loadView('views','emailAuth'),
  },
  {
    path: '/',
    name: "Home",
    component: loadView('views','Home')
  },
  {
    path: '/about',
    name: "About",
    component: loadView('views/public','About')
  },
  {
    path: '/pricing',
    name: "Pricing",
    component: loadView('views/public','Pricing')
  },
  {
    path: '/features',
    name: "Features",
    component: loadView('views/public','Features')
  },
  {
    path: '/help',
    name: "help",
    component: loadView('views','Help')
  },
  {
    path: '/login',
    name: 'Login',
    component: loadView('views','Login')
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: loadView('views','SignUp')
  },
  {
    path: '/onboarding',
    name: 'Onboarding',
    component: loadView('views','Onboarding'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/@:userName',
    name: 'User',
    component: loadView('views','User')
  },
  {
    path: '/skill/:skillId',
    name: 'Skills',
    component: loadView('views','Skills')
  },
  {
    path: "/edit-profile",
    name: "Profile",
    component: loadView('views','Profile'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/my',
    redirect: '/my/dashboard',
  },
  {
    path: '/my',
    component: loadView('views','Me'),
    meta: {
      requiresAuth: true
    },
    children:[
      {
        path:'dashboard',
        name: 'My',
        component: loadView('views/sub', 'Overview')
      },
      {
        path:'calendar',
        component: loadView('views/sub', 'Calendar')
      },
      {
        path:'settings',
        component: loadView('views/sub', 'Settings')
      },
      {
        path: 'proposals',
        name: 'Proposals',
        component: loadView('views/sub','Proposals')
      },
    ]
  },
  {
    path: '/proposals/:proposalId',
    name: 'Proposal',
    component: loadView('views','Proposal')
  },
  {
    path: "/meeting/:meetingId",
    name: "Meeting",
    component: loadView('views','Meeting'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/room/:meetingId",
    name: "Room",
    component: loadView('views','Room'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/search",
    name: "Search",
    component: loadView('views','Search'),
    meta: {
      requiresAuth: true
    }
  },
]
});

async function getUserMeta(user){
  let userMeta = await store.dispatch('getCurrentUser', user).then((userData)=>{
    // process.env.NODE_ENV === "development" ? console.log( 'currentUser', userData, currentUser, !currentUser ) : null;
    return userData
  }).catch((err)=>{
    console.log('error', err);
  });
  // process.env.NODE_ENV === "development" ? console.log('getUserMeta', userMeta) : null
  return userMeta;
}


router.beforeEach((to, from, next) => {
  // see if User exists or has permission
  let currentUser = auth.currentUser;
  // does this path require auth?
  let requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  let requiresGod = to.matched.some(record => record.meta.requiresGod);



  auth.onAuthStateChanged(function(user) {
    process.env.NODE_ENV === "development" ? console.log('onAuth user', user) : null
    if(user !== null){
      getUserMeta(user).then(userMeta => {
        // process.env.NODE_ENV === "development" ? console.log('user before meta', userMeta) : null
        beforeRouterLogic(user, userMeta);
      });
    }
    else{
        beforeRouterLogic(false, false);
    }
  });

  function beforeRouterLogic(user, userMeta){
    process.env.NODE_ENV === "development" ? console.log( 'user, userMeta, requiresAuth, requiresGod', user, userMeta, requiresAuth, requiresGod ) : null;
    // process.env.NODE_ENV === "development" ? console.log( $isUndefined(userMeta), userMeta ) : null;

      if ((to.path === '/login' || to.path ==='/sign-up') && user){
        console.log('ROUTER~~~ User is going to login');
        next('/my')
      }
      else if(!requiresAuth){
        next()
      }
      else if (requiresAuth && !user){
        console.log('ROUTER~~~ Requires Auth + No  User');
        next('/login')
      }
      else if (user && requiresAuth){
        //if user is logged but needs to complete their profile
        process.env.NODE_ENV === "development" ? console.log( !$isUndefined(userMeta.data.showProfileFirst), userMeta.data.showProfileFirst ) : null;
        // if(userMeta !== false){
        //
        // }
        if(!$isUndefined(userMeta.data.showProfileFirst)){
          if (userMeta.data.showProfileFirst === true && user && requiresAuth){
            process.env.NODE_ENV === "development" ? console.log( 'go to onboarding' ) : null;
            if(to.path === '/onboarding'){
              next()
            }
            else{
              next('/onboarding')
            }
          }
          else{ next()}
        }
        else{
          next();
        }
      }
      //     process.env.NODE_ENV === "development" ? console.log( from.path, to.path ) : null;



  }



});

export default router
