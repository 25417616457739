import Vue from 'vue';
import * as Gradientify from 'gradientify';


export default {
  install: function(Vue,) {
    // Object.defineProperty(Vue.prototype, '$gradient', { value: new Gradientify() });
    Object.defineProperties(Vue.prototype, {
      $gradient: {
        get() {
          return Gradientify
        }
      }
    });
  }
}
