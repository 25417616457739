import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import "firebase/auth";
import "firebase/storage";
import "firebase/analytics";

firebase.initializeApp({
  apiKey: "AIzaSyC17e9hcevDTGgsm72wd9xOmps2Sc4tUiU",
  authDomain: "meetable-abf7e.firebaseapp.com",
  projectId: "meetable-abf7e",
  storageBucket: "meetable-abf7e.appspot.com",
  messagingSenderId: "578906105413",
  appId: "1:578906105413:web:8983633f2ec7e411b4652c",
  measurementId: "G-8Y5TCS9Y9E"
});

export const db = firebase.firestore();
export const storage = firebase.storage();
export const auth = firebase.auth();
export const cloud = firebase.functions();
export const analytics = firebase.analytics();


// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
const { TimeStamp, GeoPoint } = firebase.firestore
export { TimeStamp, GeoPoint }


db.settings({ timestampsInSnapshots: true })
// export const remoteConfig = firebase.remoteConfig();
// remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
