import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Plugins from './plugins/plugins'
import ImageConversionPlug from './plugins/imageConversion'
import { firestorePlugin } from 'vuefire'
import InstantSearch from 'vue-instantsearch';
import Dayjs from './plugins/Day';
import Gradientify from './plugins/gradientify';
import VCalendar from 'v-calendar';
import VueResizeText from 'vue-resize-text';


import '@mdi/font/css/materialdesignicons.css'
import vuetify from './plugins/vuetify';
import {auth} from '@/firebase';


Vue.use(Plugins)
Vue.use(Dayjs)
Vue.use(Gradientify)
Vue.use(ImageConversionPlug)
Vue.use(VueResizeText)
Vue.use(firestorePlugin)
Vue.use(InstantSearch);
Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
   // ...other defaults
});


Vue.config.productionTip = false


// save a reference to the firestore database
// to access it in the future
let app;
// auth.onAuthStateChanged(function(user) {
//   process.env.NODE_ENV === "development" ? console.log( 'log check', user ) : null;
//   if (user) {
//
//     if(process.env.NODE_ENV === "development"){
//
//       process.env.NODE_ENV === "development" ? console.log( 'no analytics auth for user', user.uid ) : null;
//     }
//     else{
//       process.env.NODE_ENV === "development" ? console.log('user found', user ) : null;
//       // analytics.setUserId(user.uid);
//       // analytics.setUserProperties({
//       //   account_type: "Basic" // can help you to define audiences
//       // });
//     }
//
//   }
  if(!app){
    /* eslint-disable no-new */
    app = new Vue ({
      el:'#app',
      // template: '<App/>',
      // components: {App},
      router,
      store,
      vuetify,
      render: h => h(App),
      created:function(){
        this.$store.dispatch('init')
      }
    })
  }
// });
