import Vue from "vue";
import Vuex from "vuex";
import { vuexfireMutations, firestoreAction } from 'vuexfire'
import firebase  from "firebase/app";
import { db, auth, cloud } from '@/firebase';


Vue.use(Vuex);
Vue.config.devtools = true;
const state = {
  user: {},
  userId: '',

  meetings: [],
  meetingsLoaded: false,

  proposals: [],
  proposal: [],
  proposalsLoaded: false,

  drawer: false,
  tempPhotos: {},
  launchSearch: false,
  mobile_menu:[
    {text:'Dashboard', path:`/my/dashboard`, icon: 'mdi-chart-timeline-variant-shimmer'},
    {text:'Meetings', path:`/my/calendar`, icon: 'mdi-calendar-blank-outline'},
    {text:'Search', path:`/search`, icon: 'mdi-magnify', showMobile: true},
    {text:'Proposals', path:`/my/proposals`, icon: 'mdi-hand'},
    {text:'Settings', path:`/my/settings`, icon: 'mdi-account-outline'},
    ]
};

const actions = {
  init: firestoreAction((context)=>{
    process.env.NODE_ENV === "development" ? console.log( 'conetnxt', context ) : null;
    // context.bindFirestoreRef('todos', currentTodos)
  }),
  login({ commit }, {email, password, kind, NGROK }) {
    return new Promise((resolve, reject) => {
      var varUrl = process.env.VUE_APP_SIGNIN_URL;
      var actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be whitelisted in the Firebase Console.
      url: NGROK ? 'https://46c603d537dc.ngrok.io' : `${varUrl}/emailAuth`,
      // This must be true.
      handleCodeInApp: true,
      // iOS: {
      //   bundleId: 'com.keypls.KeyBot'
      // }
      // android: {
        //   packageName: 'com.example.android',
        //   installApp: true,
        //   minimumVersion: '12'
        // }
      };
      console.log('email', email, `${varUrl}/emailAuth`);
      let sendVerificationEmail = cloud.httpsCallable('sendVerificationEmail');
      sendVerificationEmail({email: email, actionCodeSettings: actionCodeSettings}).then(function(result) {
        resolve();
      });
    });
  },

  async loginSocial({ commit }) {
    return new Promise((resolve, reject) => {
      var provider = new firebase.auth.GoogleAuthProvider();
      auth.signInWithPopup(provider).then((response) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = response.credential.accessToken;
        // The signed-in user info.
        var user = response.user.uid;
        commit('SET_USER_ID', user);
        process.env.NODE_ENV === "development" ? console.log( user) : null;

        let userCreated = db.collection('users').doc(user).get().then((result)=>{
          commit('SET_USER', result.data());
          return result.data();
        })

        resolve(userCreated);

        // ...
      }).catch(function(error) {
        alert(error);
        console.log('error', error);
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
        reject(error);
      });
    });
  },

  logout({ commit }) {
    return new Promise((resolve, reject) => {
      auth.signOut()
        .then(() => {
          commit('LOGOUT');
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getCurrentUser({ commit }) {
    return new Promise((resolve, reject) => {
      auth.onAuthStateChanged((user) => {
        if (user) {
          // process.env.NODE_ENV === "development" ? console.log( 'user~~~~``', user ) : null;
          if($isEmpty(state.user)){
            // process.env.NODE_ENV === "development" ? console.log( 'get user from db....' ) : null;
            db.collection('users').doc(user.uid).onSnapshot((result)=>{
              // process.env.NODE_ENV === "development" ? console.log( 'results', Object.hasOwnProperty.call(result.data(), 'account'), result.data() ) : null;
              if(result.exists){
                if(Object.hasOwnProperty.call(result.data(), 'account')) {
                  commit('SET_USER_ID', user);
                  commit('SET_USER', result.data());
                  let localUser = {
                    userId: state.userId,
                    data: state.user
                  }
                  resolve(localUser);
                }
                else{
                  reject('no user')
                }
              }
              else{
                reject('no user, maybe used to exists?')
              }
            })
          }
          else{
            process.env.NODE_ENV === "development" ? console.log( 'get user from local....' , state.user) : null;
            let localUser = {
              userId: state.userId,
              data: state.user
            }
            resolve(localUser)
          }
        } else {
          reject('User is not logged in.');
        }
      });
    });
  },

  storePhotos({ commit, state }, {data}) {
    return new Promise((resolve, reject) => {
      process.env.NODE_ENV === "development" ? console.log( 'set photo', data ) : null;
      // let localUser = {
      //   userId: state.userId,
      //   data: state.user
      // }
      //   return resolve(localUser)
      commit('SET_PHOTO_LOCATION', data);
      resolve();
    });
  },


  // eslint-disable-next-line no-unused-vars
  saveUser({ commit, state }, {user}) {
    state.user = user;
  },

  // eslint-disable-next-line no-unused-vars
  updateUser({commit, state}, data) {
    process.env.NODE_ENV === "development" ? console.log( 'update data',data ) : null;
    return new Promise((resolve, reject) => {
      const dbRef = db.collection('users').doc(state.userId);
      dbRef.update(data).then(function(result) {
        process.env.NODE_ENV === "development" ? console.log( 'result ', result ) : null;
          resolve('ok')
        // ...
      }).catch(function(error) {
        // Handle Errors here.
        reject(error);
      });
    });
  },
    //action to open or close the nav
    // eslint-disable-next-line no-unused-vars
  launchSearch({commit}, val){
    // process.env.NODE_ENV === "development" ? console.log('store drawer', val) : null
    store.commit('LAUNCH_SEARCH', val)
  },

  // getMeetings: firestoreAction(({ bindFirestoreRef }, {ref}) => {
  //     // return the promise returned by `bindFirestoreRef`
  //     return bindFirestoreRef('meetings', ref)
  //   }),

  getProposals({commit, state}, data){
    process.env.NODE_ENV === "development" ? console.log( 'get proposals', data ) : null;
    // are proposals loaded alreadY?
    let allRef = db.collection('proposals').where('attendees_Ids', 'array-contains', data.userId);
    let activeRef = db.collection('proposals').where('attendees_Ids', 'array-contains', data.userId).where('active', '==', true);
    let dbRef = data.flag === 'active' ? activeRef : allRef;
    if(state.proposalsLoaded != true){
      dbRef.onSnapshot(querySnapShot =>{
        process.env.NODE_ENV === "development" ? console.log( 'something changed' ) : null;
        state.proposals = [];
        state.proposalsLoaded = true;
          querySnapShot.forEach((doc, i) => {
            let proposal = {id: doc.id, data:doc.data()}
            store.commit('SET_PROPOSALS', proposal)
          });
      });
    }
  },

  getProposal({commit, state}, proposalId){
    process.env.NODE_ENV === "development" ? console.log( 'get proposal~~~', proposalId ) : null;
      db.collection('proposals').doc(proposalId).onSnapshot({
        includeMetadataChanges: true
        }, function(doc) {
            let proposal = {id: doc.id, data:doc.data()}
            let alreadyExists = state.proposals.findIndex(item => {return item.id === doc.id})
            process.env.NODE_ENV === "development" ? console.log( 'exists already, doc', alreadyExists ,doc.data() ) : null;
            store.commit('SET_PROPOSAL', proposal)
        });
  },

  async rejectProposal({commit, state}, data){
    let rejected = await db.collection('proposals').doc(data.proposalId).update(data.proposal);
    return rejected
  },

  async acceptProposal({commit, state}, data){
    let approved = await db.collection('proposals').doc(data.proposalId).update(data.proposal);
    return approved
  },



  getMeetings({commit, state}, data){
    process.env.NODE_ENV === "development" ? console.log( 'get meetings', data ) : null;
    // are proposals loaded alreadY?
    let allRef = db.collection('meetings').where('attendees_Ids', 'array-contains', data.userId);
    let activeRef = db.collection('meetings').where('attendees_Ids', 'array-contains', data.userId).where('active', '==', true);
    let dbRef = data.flag === 'active' ? activeRef : allRef;
    if(state.meetingsLoaded != true){
      dbRef.onSnapshot(querySnapShot =>{
        process.env.NODE_ENV === "development" ? console.log( 'something changed' ) : null;
        state.meetings = [];
        state.meetingsLoaded = true;
          querySnapShot.forEach((doc, i) => {
            let meeting = {id: doc.id, data:doc.data()}
            store.commit('SET_MEETINGS', meeting)
          });
      });
    }
  },



}

const mutations = {
  ...vuexfireMutations,
  // eslint-disable-next-line no-unused-vars
  LOGOUT(state, user) {
    state.user = null;
    state.userId = null;
  },
  SET_USER(state, user) {
    state.user = user;
  },
  SET_USER_ID(state, user) {
    state.userId = user.uid;
  },
  SET_MEETINGS(state, meetings) {
    state.meetings.push(meetings);
  },
  SET_PROPOSAL(state, proposal) {
    state.proposal = proposal;
  },
  SET_PROPOSALS(state, proposals) {
    state.proposals.push(proposals);
  },
  SET_PHOTO_LOCATION(state, data) {
    state.tempPhotos = {
      ...state.tempPhotos,
      [data.location]: data.url
    }
    // state.songs = {
    //    ...state.songs,
    //    [ songid ]: { ...song } // break any object references, thank me later
    //  }
  },
  LAUNCH_SEARCH(state, value){
    state.launchSearch = value;
  },
};

const getters = {
  GET_USER: state => state.user,
  GET_USER_ID: state => state.userId,
  GET_PHOTO_LOCATION: state => state.tempPhotos,
  isAlreadyProposed: (state) => (userId) => {
      // return state.proposals.find(propo => propo.at === id)
  }
}


const store = new Vuex.Store({
  state,
  mutations,
  actions,
  getters
})


function $isEmpty (obj, keyy) {
  if(keyy != undefined){
    return Object.hasOwnProperty.call(obj, keyy)
  }
  else{
    for(var key in obj) {
      if(Object.prototype.hasOwnProperty.call(obj,key))
      return false;
    }
    return true;
  }
}


export default store
